import React, {useEffect} from 'react';
import rhombus from '../images/contact-rhombus.svg';
import {Container} from 'react-bootstrap';
import Footer from "../Components/Footer";

const Contact = () => {

    useEffect(() => {
        document.body.classList.add('dark')
    },[])

    return (
        <div className="contact-page">
            <Container>
                <div className="contact">
                    <div className="contact__left">
                        <div className="contact__description">
                            <h4 className="visible-content">We Are Here</h4>
                            <p className="visible-content">
                                Celesta Platform ltd<br />
                                Registration number: HE422856<br />
                                Agias Fylaxeos 95, 3087 Limassol
                            </p>
                        </div>
                    </div>
                    <div className="contact__right">
                        <h4 className="visible-content">Let’s Talk</h4>
                        <p className="contact__text visible-content">Contact us via
                            <a href="mailto:contact@celesta.io"> contact@celesta.io </a>
                            {/*or send email using contact form below.*/}
                        </p>
                    </div>
                </div>
                <div className="contact-page__rhombus">
                    <img src={rhombus} alt="Rhombus"/>
                </div>
            </Container>
            <Footer isVisible={true}/>
        </div>
    );
}

export default Contact;
