import React, { useEffect } from "react";
import Header from "./Components/Header";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./Router";
import { Helmet } from "react-helmet";

function App() {
    useEffect(() => {
        // Inject the GTM noscript tag into the body
        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6QN4HNJ"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
        document.body.appendChild(noscript);

        return () => {
            document.body.removeChild(noscript);
        };
    }, []);

    return (
        <Router>
            <Helmet>
                {/* Google Tag Manager Script */}
                <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-W6QN4HNJ');
                    `}
                </script>
            </Helmet>

            <Header />
            <AppRouter />
        </Router>
    );
}

export default App;