import React, {Component} from 'react';
import { Parallax } from 'react-parallax';
import {Container} from 'react-bootstrap';
import leftVector from "../images/services_vector_left.svg";
import rightVector from "../images/services_vector_right.svg";
import {Link} from "react-router-dom";
import Footer from "../Components/Footer";
import bg_big_elements from "../images/services_big_elements.png";
import bg_small_elements from "../images/services_small_elements.png";

class Services extends Component {
    render() {
        return (
            <div className="services-page page">
                <Container>
                    <div className="page-title">
                        <h2 className="title-120">
                            <div className="page__starter">
                                <span className="page__starter-block"></span>
                            </div>
                            <div className="page-title--vector">
                                <img src={leftVector} alt="Vector"/>
                            </div>
                            <div className="visible-content">Our</div>
                            <div className="visible-content">Services</div>
                            <div className="page-title--vector-right">
                                <img src={rightVector} alt="Vector"/>
                            </div>
                        </h2>
                    </div>
                </Container>
                <Parallax bgImage={bg_big_elements} strength={-1100} bgImageStyle={{height:'100%', top:'39%', width:'100%'}} bgImageAlt="services background">
                    <div className="services-content">
                        <Parallax bgImage={bg_small_elements} style={{position:'absolute'}} strength={300} bgImageStyle={{height:'100%', top:'-4%', width:'100%'}} bgImageAlt="services background" />
                        <Container className="visible-content">
                            <section>
                                <h3 className="title-120--ghost">Turnkey Casino</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>Celesta team is taking care of the creation and maintenance of your casino</li>
                                            <li>Best solution to save time & resources</li>
                                            <li>You manage the marketing & promotions of your brand</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <h3 className="title-120--ghost">White Label</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>Celesta team is preparing the casino as you want</li>
                                            <li>When it’s ready, you can run it where you want, as you want</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <h3 className="title-120--ghost">Self Service</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>Celesta team is providing you all the information necessary to kick off your casino with your specialized IT team</li>
                                            <li>You’re independent on each modification</li>
                                            <li>Cost & time saving</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <h3 className="title-120--ghost">Consulting</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>The Celesta expert team is analyzing your entire organization or specific subject to provide your external feedbacks</li>
                                            <li>Companies structure</li>
                                            <li>Promotions & SEO</li>
                                            <li>Hosting</li>
                                            <li>Licences</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </Container>
                    </div>
                </Parallax>
                <div className="page-footer">
                    <Container>
                        <div className="row">
                            <div className="col-6 d-flex justify-content-end align-items-center px-0 page-footer__text">
                                <span>
                                    Many providers are already with us. <br/>
                                    Why don’t you connected yet?
                                </span>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <Link to="#providers" className="btn--green">
                                    <span>Providers</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </div>
                        </div>
                    </Container>
                </div>
                <Footer isVisible={true}/>
            </div>
        );
    }
}

export default Services;
