import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import leftVector from "../images/providers_vector_left.svg";
import rightVector from "../images/providers_vector_right.svg";
import {Link} from "react-router-dom";
import Footer from "../Components/Footer";
import bg_small_elements from "../images/providers_small_elements.png";
import bg_big_elements from "../images/providers_big_elements.png";

const Providers = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const importAllImages = (r) => {
        let images = {};
        r.keys().map(item => ( images[item.replace('./', '')] = r(item)));
        return images;
    }
    const images = importAllImages(require.context('../images/providers', false, /.svg/));

    return (
        <div className="providers-page page">
            <Container>
                <div className="page-title">
                    <h2 className="title-120">
                        <div className="page__starter">
                            <span className="page__starter-block"></span>
                        </div>
                        <div className="page-title--vector">
                            <img src={leftVector} alt="Vector"/>
                        </div>
                        <div className="visible-content">Our</div>
                        <div className="visible-content">Game</div>
                        <div className="visible-content">Providers</div>
                        <div className="page-title--vector-right">
                            <img src={rightVector} alt="Vector"/>
                        </div>
                    </h2>
                </div>
            </Container>
            <Parallax bgImage={bg_big_elements} strength={-500} bgImageStyle={{height:'100%', top:'15%', width:'100%'}} bgImageAlt="providers background">
                <div className="providers-content">
                    <Parallax bgImage={bg_small_elements} style={{position:'absolute'}} strength={300} bgImageStyle={{height:'100%', top:'0%', width:'100%'}} bgImageAlt="services background" />
                    <Container className="providers-container">
                        <Row className="visible-content">
                            <Col>
                                <img src={images['1.svg'].default} alt="Spinthon"/>
                                <img src={images['2.svg'].default} alt="Relax Gaming"/>
                                <img src={images['3.svg'].default} alt="Play'nGO"/>
                                <img src={images['4.svg'].default} alt="Red Tiger"/>
                                <img src={images['5.svg'].default} alt="EGT"/>
                                <img src={images['6.svg'].default} alt="Yggdrasil"/>
                                <img src={images['7.svg'].default} alt="Apollo Games"/>
                                <img src={images['8.svg'].default} alt="Asia Gaming"/>
                                <img src={images['9.svg'].default} alt="CQ9 Gaming"/>
                                <img src={images['10.svg'].default} alt="Ezugi Smart Move"/>
                                <img src={images['11.svg'].default} alt="Game Art"/>
                                <img src={images['12.svg'].default} alt="Ho Gaming"/>
                                <img src={images['13.svg'].default} alt="Markor Technology"/>
                                <img src={images['14.svg'].default} alt="Blueprint gaming"/>
                                <img src={images['15.svg'].default} alt="Pariplay"/>
                                <img src={images['16.svg'].default} alt="Spinmatic"/>
                                <img src={images['17.svg'].default} alt="Spinomenal"/>
                                <img src={images['18.svg'].default} alt="Wacs"/>
                            </Col>
                            <Col>
                                <img src={images['19.svg'].default} alt="Fugaso"/>
                                <img src={images['20.svg'].default} alt="ELK Studios"/>
                                <img src={images['21.svg'].default} alt="Wazdan"/>
                                <img src={images['22.svg'].default} alt="Isoftbet"/>
                                <img src={images['23.svg'].default} alt="Booongo"/>
                                <img src={images['24.svg'].default} alt="Red rake"/>
                                <img src={images['25.svg'].default} alt="Live Slots"/>
                                <img src={images['26.svg'].default} alt="Bet Games"/>
                                <img src={images['27.svg'].default} alt="Concept Gaming"/>
                                <img src={images['28.svg'].default} alt="Golden Race"/>
                                <img src={images['29.svg'].default} alt="Kiron"/>
                                <img src={images['30.svg'].default} alt="Lucky Streak"/>
                                <img src={images['31.svg'].default} alt="Microgaming"/>
                                <img src={images['32.svg'].default} alt="Salsa Technology"/>
                                <img src={images['33.svg'].default} alt="Reel NRG"/>
                                <img src={images['34.svg'].default} alt="Spigo"/>
                                <img src={images['35.svg'].default} alt="Worldmatch"/>
                                <img src={images['36.svg'].default} alt="PRO Gaming"/>
                            </Col>
                            <Col>
                                <img src={images['37.svg'].default} alt="Push Gaming"/>
                                <img src={images['38.svg'].default} alt="Quickspin"/>
                                <img src={images['39.svg'].default} alt="Booming Games"/>
                                <img src={images['40.svg'].default} alt="Cayetano Gaming"/>
                                <img src={images['41.svg'].default} alt="Pragmatic Play"/>
                                <img src={images['42.svg'].default} alt="Amatic Industries"/>
                                <img src={images['43.svg'].default} alt="Atmosfera"/>
                                <img src={images['44.svg'].default} alt="Belatra Games"/>
                                <img src={images['45.svg'].default} alt="EVO Play"/>
                                <img src={images['46.svg'].default} alt="Ganapati"/>
                                <img src={images['47.svg'].default} alt="Genii"/>
                                <img src={images['48.svg'].default} alt="Live Games"/>
                                <img src={images['49.svg'].default} alt="Mr Slotty"/>
                                <img src={images['50.svg'].default} alt="Nolimit City"/>
                                <img src={images['51.svg'].default} alt="Platipus"/>
                                <img src={images['52.svg'].default} alt="Thunderkick"/>
                                <img src={images['53.svg'].default} alt="Tom Horn Gaming"/>
                                <img src={images['54.svg'].default} alt="BF"/>
                            </Col>
                            <Col>
                                <img src={images['55.svg'].default} alt="Big Time Gaming"/>
                                <img src={images['56.svg'].default} alt="Betsoft"/>
                                <img src={images['57.svg'].default} alt="Playson"/>
                                <img src={images['58.svg'].default} alt="Netent"/>
                                <img src={images['59.svg'].default} alt="Skywind Group"/>
                                <img src={images['60.svg'].default} alt="Ainsworth"/>
                                <img src={images['61.svg'].default} alt="Authentic Gaming"/>
                                <img src={images['62.svg'].default} alt="Evo SV"/>
                                <img src={images['63.svg'].default} alt="Evolution Gaming"/>
                                <img src={images['64.svg'].default} alt="Habanero"/>
                                <img src={images['65.svg'].default} alt="Igrosoft"/>
                                <img src={images['66.svg'].default} alt="Leander Studios"/>
                                <img src={images['67.svg'].default} alt="Pocket Games Soft"/>
                                <img src={images['68.svg'].default} alt="SA Gaming"/>
                                <img src={images['69.svg'].default} alt="Spadegaming"/>
                                <img src={images['70.svg'].default} alt="Vivo Gaming"/>
                                <img src={images['71.svg'].default} alt="Spinola Gaming"/>
                                <img src={images['72.svg'].default} alt="Stake Logic"/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Parallax>
            <div className="page-footer">
                <Container>
                    <div className="row">
                        <div className="col-6 d-flex justify-content-end align-items-center px-0 page-footer__text">
                            <span>
                                Impressive, huh? <br/>
                                Don’t lose time, join our platform!
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <Link to="#contact" className="btn--white">
                                <span>Contact</span>
                                <span className="arrow-wrapper"></span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer isVisible={true}/>
        </div>
    );
}

export default Providers;
