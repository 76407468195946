import React from 'react';
import {Container} from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import CardIcon1 from '../images/1_card_icon.svg';
import CardIcon2 from '../images/2_card_icon.svg';
import CardIcon3 from '../images/3_card_icon.svg';
import CardIcon4 from '../images/4_card_icon.svg';
import arrow from '../images/about_arrow.svg';
import leftVector from '../images/about_vector_left.svg';
import rightVector from '../images/about_vector_right.svg';
import Footer from "../Components/Footer";
import {Link} from "react-router-dom";
import bg_big_elements from "../images/about_big_elements.png";
import bg_small_elements from "../images/about_small_elements.png";

const About = () => {

    return (
        <div className="about-page page">
            <Container>
                <div className="page-title">
                    <h2 className="title-120">
                        <div className="page__starter">
                            <span className="page__starter-block"></span>
                        </div>
                        <div className="page-title--vector">
                            <img src={leftVector} alt="Vector"/>
                        </div>
                        <div className=" visible-content">Discover</div>
                        <div className=" visible-content">Modularity</div>
                        <div className=" visible-content">World</div>
                        <div className="page-title--vector-right">
                            <img src={rightVector} alt="Vector"/>
                        </div>
                    </h2>
                </div>
            </Container>
            <Parallax bgImage={bg_big_elements} strength={-1100} bgImageStyle={{height:'130%', top:'24%', width:'100%'}} bgImageAlt="about background">
            <Parallax bgImage={bg_small_elements} style={{position:'absolute'}} strength={400} bgImageStyle={{height:'130%', top:'5%', width:'100%'}} bgImageAlt="services background" />
                <Container>
                    <div className="description row">
                        <div className="row visible-content">
                            <div className="col-lg-6">
                                <h4 className="title-32">What is Celesta?</h4>
                                <p>
                                    Celesta platform is new generation platform.<br/>
                                    It’s service will be adjusted to your need by a simple configuration. It’s architecture
                                    based on
                                    vertical and horizontal scaling will reply to all your needs.
                                </p>
                            </div>
                        </div>
                        <div className="row visible-content">
                            <div className="col-lg-6 text-center d-none d-lg-block">
                                <img src={arrow} alt="Arrow"/>
                            </div>
                            <div className="col-lg-6">
                                <p>
                                    In 2018 we kickoff the Celesta project to build our first online casino after a fast
                                    success,
                                    the ambition was to launch a second one quickly. With strategy and our talent teams, we were
                                    able to do it in less than a year. After 2 years, 2 casinos are already running on the
                                    platform.<br/>
                                    <span> </span><br/>
                                    Our success stories created non voluntary attention of potential clients, who are now users.
                                    We are now hosting several casinos.
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="adventages">
                    <Container>
                        <h2 className="title-120 visible-content">Take advantages</h2>
                        <h3 className="title-64 visible-content">of our new features</h3>
                        <div className="cards flex visible-content">
                            <div className="card flex">
                                <img src={CardIcon1} alt="Modularity icon"/>
                                <h5 className="title-32--dark">Modular content</h5>
                                <span>CMS to manage content</span>
                            </div>
                            <div className="card flex">
                                <img src={CardIcon2} alt="Statistics icon"/>
                                <h5 className="title-32--dark">Detailed statistics</h5>
                                <span>BI platform and report already integrated</span>
                            </div>
                            <div className="card flex">
                                <img src={CardIcon3} alt="Scalability icon"/>
                                <h5 className="title-32--dark">Scalability</h5>
                                <span>Celesta will follow your need and adapt automatically</span>
                            </div>
                            <div className="card flex">
                                <img src={CardIcon4} alt="Security icon"/>
                                <h5 className="title-32--dark">Highly secure</h5>
                                <span>Payment system ultra secured</span>
                            </div>
                        </div>
                    </Container>
                </div>
            </Parallax>
            <div className="page-footer">
                <Container>
                    <div className="row">
                        <div className="col-6 d-flex justify-content-end align-items-center px-0 page-footer__text">
                            <span>
                                Ready to continue? <br/>
                                Check out our business solutions.
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <Link to="#solutions" className="btn--pink">
                                <span>Solutions</span>
                                <span className="arrow-wrapper"></span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer isVisible={true}/>
        </div>
    );
}

export default About;
