import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import leftVector from "../images/solutions_vector_left.svg";
import rightVector from "../images/solutions_vector_right.svg";
import {Link} from "react-router-dom";
import Footer from "../Components/Footer";
import bg_small_elements from "../images/solutions_small_elements.png";
import bg_big_elements from "../images/solutions_big_elements.png";

class Solutions extends Component {
    render() {
        return (
            <div className="solutions-page page">
                <Container>
                    <div className="page-title">
                        <h2 className="title-120">
                            <div className="page__starter">
                                <span className="page__starter-block"></span>
                            </div>
                            <div className="page-title--vector">
                                <img src={leftVector} alt="Vector"/>
                            </div>
                            <div className="visible-content">Our</div>
                            <div className="visible-content">Business</div>
                            <div className="visible-content">Solutions</div>
                            <div className="page-title--vector-right">
                                <img src={rightVector} alt="Vector"/>
                            </div>
                        </h2>
                    </div>
                </Container>
                <Parallax bgImage={bg_big_elements} strength={-500} bgImageStyle={{height:'100%', top:'25%', width:'100%'}} bgImageAlt="services background">
                    <div className="solutions-content">
                        <Parallax bgImage={bg_small_elements} style={{position:'absolute'}} strength={300} bgImageStyle={{height:'100%', top:'-8%', width:'100%'}} bgImageAlt="services background" />
                        <Container>
                            <section className="visible-content">
                                <h3 className="title-120--ghost">Platform</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>Player account management</li>
                                            <li>Games management</li>
                                            <li>Payment processing</li>
                                            <li>CRM & automated mailing system</li>
                                            <li>Affiliate system</li>
                                            <li>Bonus & rewards</li>
                                        </ul>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>BI</li>
                                            <li>CMS</li>
                                            <li>Compliance with main regulations (MGA, UKGC, SGA)</li>
                                            <li>Anti-Fraud engine</li>
                                            <li>Real time rules engine</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section className="visible-content">
                                <h3 className="title-120--ghost">Game Gateway</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>7000+ games</li>
                                            <li>Slots games</li>
                                            <li>Live games</li>
                                        </ul>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>Poker</li>
                                            <li>Skill games</li>
                                            <li>Lottery, scratch games</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section className="visible-content">
                                <h3 className="title-120--ghost">Payment Gateway</h3>
                                <div className="row multylist">
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>10+ integrations</li>
                                            <li>E-wallets solutions</li>
                                            <li>Bank cards solutions</li>
                                        </ul>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="marked-list">
                                            <li>Pay in/out automated</li>
                                            <li>Advanced consolidation system</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </Container>
                    </div>
                </Parallax>
                <div className="page-footer">
                    <Container>
                        <div className="row">
                            <div className="col-6 d-flex justify-content-end align-items-center px-0 page-footer__text">
                                <span>
                                    Interesting, isn't it? <br/>
                                    Check out our range of services.
                                </span>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <Link to="#services" className="btn--blue">
                                    <span>Services</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </div>
                        </div>
                    </Container>
                </div>
                <Footer isVisible={true}/>
            </div>
        );
    }
}

export default Solutions;
