import React, {useEffect, Fragment, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { isMobile } from 'react-device-detect';
import Footer from '../Components/Footer';
import {Link} from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import CelestaBigLogo from '../images/main_logo.png';
import CelestaBigText from '../images/celesta_text.svg';
import aboutLeftVector from '../images/about_vector_left.svg';
import aboutRightVector from '../images/about_vector_right.svg';
import solutionsLeftVector from '../images/solutions_vector_left.svg';
import solutionsRightVector from '../images/solutions_vector_right.svg';
import servicesLeftVector from '../images/services_vector_left.svg';
import servicesRightVector from '../images/services_vector_right.svg';
import providersLeftVector from '../images/providers_vector_left.svg';
import providersRightVector from '../images/providers_vector_right.svg';
import contactLeftVector from '../images/contact_vector_left.svg';
import contactRightVector from '../images/contact-rhombus.svg';


const Index = () => {
    const [isStatic] = useState(false);
    const [isVisible, setFooterVisibility] = useState(!!isStatic);

    useEffect(() => {
        document.body.classList.add('dark');
        !isStatic && window.addEventListener('wheel', handleScroll, { passive: true });
        return () => window.removeEventListener('wheel', handleScroll);
    },[isStatic])


    const handleScroll = (e) => {
        if(e.deltaY > 0) {
            setFooterVisibility(true)
        } else {
            setFooterVisibility(false)
        }
    }

    if (isMobile) {
        return (
            <Fragment>
                <Carousel
                    className={`home-page carousel home ${isVisible ? "indicators-up" :""}`}
                    controls={false}
                    pause="hover"
                    interval={null}
                >
                    <Carousel.Item className="home">
                        <div className="carousel__item-inner">
                            <h1>Celesta</h1>
                            <div className="image--big">
                                <Tilt tiltMaxAngleX={15} tiltMaxAngleY={15} gyroscope={true}>
                                    <img src={CelestaBigLogo} alt="Celesta logo" />
                                </Tilt>
                            </div>
                            <Carousel.Caption>
                                <div className="carousel__center">
                                    <img src={CelestaBigText} alt="Celesta" className="celesta visible-content carousel__title"/>
                                </div>
                                <Link to="#about" className="btn">
                                    <span>Discover</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="about">
                        <div className="carousel__item-inner">
                            <div className="image--big">
                                <img src={aboutLeftVector} alt="About vector"/>
                            </div>
                            <div className="image--small">
                                <img src={aboutRightVector} alt="About vector"/>
                            </div>
                            <Carousel.Caption>
                                <div className="carousel__center">
                                    <h2 className="visible-content carousel__title">About</h2>
                                </div>
                                <Link to="#about" className="btn--white">
                                    <span>Learn more</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="solutions">
                        <div className="carousel__item-inner">
                            <div className="image--big">
                                <img src={solutionsLeftVector} alt="Solutions vector"/>
                            </div>
                            <div className="image--small">
                                <img src={solutionsRightVector} alt="Solutions vector"/>
                            </div>
                            <Carousel.Caption>
                                <div className="carousel__center">
                                    <h2 className="visible-content carousel__title">Solutions</h2>
                                </div>
                                <Link to="#solutions" className="btn--white">
                                    <span>Learn more</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="services">
                        <div className="carousel__item-inner">
                            <div className="image--big">
                                <img src={servicesLeftVector} alt="Services vector"/>
                            </div>
                            <div className="image--small">
                                <img src={servicesRightVector} alt="Services vector"/>
                            </div>
                            <Carousel.Caption>
                                <div className="carousel__center">
                                    <h2 className="visible-content carousel__title">Services</h2>
                                </div>
                                <Link to="#services" className="btn--white">
                                    <span>Learn more</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="providers">
                        <div className="carousel__item-inner">
                            <div className="image--big">
                                <img src={providersLeftVector} alt="Providers vector"/>
                            </div>
                            <div className="image--small">
                                <img src={providersRightVector} alt="Providers vector"/>
                            </div>
                            <Carousel.Caption>
                                <div className="carousel__center">
                                    <h2 className="visible-content carousel__title">Providers</h2>
                                </div>
                                <Link to="#providers" className="btn--white">
                                    <span>Learn more</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="contact">
                        <div className="carousel__item-inner">
                            <div className="image--big">
                                <img src={contactRightVector} alt="Contact vector"/>
                            </div>
                            <div className="image--small">
                                <img src={contactLeftVector} alt="Contact vector"/>
                            </div>
                            <Carousel.Caption>
                                <div className="carousel__center">
                                    <h2 className="visible-content carousel__title">Contact</h2>
                                </div>
                                <Link to="#contact" className="btn--white">
                                    <span>Learn more</span>
                                    <span className="arrow-wrapper"></span>
                                </Link>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                </Carousel>
                <Footer isVisible={false} />
            </Fragment>
        )
    }

    return (
        <div className="home-page">
            <h1>Celesta</h1>
            <div className="home-image">
                <Tilt trackOnWindow={true} tiltMaxAngleX={10} tiltMaxAngleY={10}>
                    <img src={CelestaBigLogo} alt="Celesta logo"/>
                </Tilt>
            </div>
            <div className="home-page__content visible-content">
                <img src={CelestaBigText} alt="Celesta" className="celesta"/>
                <nav className="home__nav">
                    <Link to="#about" className="about">About</Link>
                    <Link to="#solutions" className="solutions">Solutions</Link>
                    <Link to="#services" className="services">Services</Link>
                    <Link to="#providers" className="providers">Providers</Link>
                    <Link to="#contact" className="contact">Contact</Link>
                    <Link to="#careers" className="careers">Careers</Link>
                </nav>
                <Link to="#about" className="btn">
                    <span>Discover</span>
                    <span className="arrow-wrapper"></span>
                </Link>
            </div>
            <Footer isVisible={false} />
        </div>
    )
}

export default Index;
