import { Container } from "react-bootstrap";
import cube from "../images/Cube.svg";
import Footer from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { isMobile } from "react-device-detect";
import Spiner from "../UI/Spiner";

const Careers = () =>{
    const [isLoading,setLoading]=useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            const bambooList = document.querySelector("ul.BambooHR-ATS-Department-List");
            const bambooTarget = document.querySelector("#bamboo-target");
            if (bambooList && bambooTarget) {
                const clonedBambooList = bambooList.cloneNode(true);
                bambooTarget.appendChild(clonedBambooList);
            }
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <div  className="careers-page page">

            <Container>
                <div className="page-title">
                    <h2 className="title-120">
                        <div className="page__starter">
                            <span className="page__starter-block"></span>
                        </div>
                        <div className="page-title--vector">
                            <img src={cube} alt="Vector"/>
                        </div>
                            <div className="visible-content">We</div>
                            <div className="visible-content">Are</div>
                            <div className="visible-content">Hiring</div>
                        <div className="page-title--vector-right">
                            <img src={cube} alt="Vector"/>
                        </div>
                    </h2>
                </div>
                {isLoading && <Spiner/>}
                <section id='bamboo-target'
                         className={`row visible-content cards-container ${isMobile && 'section-mobile'}`}/>
                {!isLoading && document.querySelectorAll("#bamboo-target ul").length === 0 &&
                    <div className="page-footer__text" style={{textAlign: "center", marginBottom: "100px"}}>
                        No job offer yet, come later ...
                    </div>
                }
            </Container>
            <div className="page-footer">
                <Container>
                    <div className="row">
                        <div className="col-6 d-flex justify-content-end align-items-center px-0 page-footer__text">
                            <span>
                                Impressive, huh? <br/>
                                Don’t lose time, join our platform!
                            </span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <Link to="#contact" className="btn--white">
                                <span>Contact</span>
                                <span className="arrow-wrapper"></span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer isVisible={true}/>
        </div>
    )}

export default Careers
