import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import logo from "../images/top_logo.svg";

const Footer = (props) => {
    const isStatic = props.isVisible;
    const [isVisible, setFooterVisibility] = useState(!!isStatic);
    const [show, setShow] = useState(false);

    useEffect(() => {
        !isStatic && window.addEventListener('wheel', handleScroll, { passive: true })
        return () => window.removeEventListener('wheel', handleScroll);
    },[isStatic]);

    const handleScroll = (e) => {
        if(e.deltaY > 0) {
            setFooterVisibility(true)
        } else {
            setFooterVisibility(false)
        }
    };

    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer className={`footer ${isVisible ? 'showing' : ''} ${isStatic ? 'static' : ''}`}>
                <Container fluid>
                    <span>Celesta Platform ltd© {currentYear}.<br /> All rights reserved.</span>
                    <span className="modalButton" onClick={() => setShow(true)}>Legal Mentions</span>
                </Container>
            </footer>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                fullscreen={true}
                animation={true}
                centered
                scrollable
            >
                <Modal.Header>
                    <img
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                    <button type="button" className="button-close" onClick={() => setShow(false)} aria-label="Close">
                        <span className="button-close-icon"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="title-120">Legal Mentions</h2>
                    <p>
                        Terms and conditions apply to all services provided by Celesta Platform ltd to our clients. By signing the Order Form or otherwise engaging Celesta Platform ltd the Client confirms acknowledgement with these legal mentions.
                    </p>
                    <p>
                        Celesta Platform ltd address: Registration number: HE422856, Agias Fylaxeos 95, 3087 Limassol.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Footer;
