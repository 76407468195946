import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import Index from "./Pages/Index";
import About from "./Pages/About";
import Solutions from "./Pages/Solutions";
import Services from "./Pages/Services";
import Providers from "./Pages/Providers";
import Contact from "./Pages/Contact";
import Careers from "./Pages/Careers";

const AppRouter = () => {
    const [hash, setHash] = useState("");
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        setHash(location.hash);
    },[location])

    const renderHashRoad = (hash) => {
        switch (hash) {
            case "#about":
                return <About/>
            case "#solutions":
                return <Solutions/>
            case "#services":
                return <Services/>
            case "#providers":
                return <Providers/>
            case "#contact":
                return <Contact/>
            case "#careers":
                return <Careers/>
            default:
                return <Index/>
        }
    }

    return (
        <SwitchTransition>
            <CSSTransition
                key={hash}
                timeout={500}
                classNames="page"
                unmountOnExit
            >
                {renderHashRoad(hash)}
            </CSSTransition>
        </SwitchTransition>
    );
}

export default AppRouter;
