import React, {useState} from 'react';
import {Navbar, Container} from 'react-bootstrap';
import logo from '../images/top_logo.svg'
import {Link} from "react-router-dom";

const Header = () => {
    const [menuOpened, setMenuOpened] = useState(false);

    const handleMenu = () => {
        setMenuOpened(!menuOpened);
    }

    const closeMenu = () => {
        setMenuOpened(false);
    }

    return (
        <Navbar collapseOnSelect expand="xxxl" id="navigaton" variant="dark">
            <Container fluid>
                <Link to="/" onClick={()=>closeMenu()} className="logo">
                    <img
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                </Link>
                <button type="button" className={`navbar-toggler ${!menuOpened ? "collapsed" : ""}`} onClick={()=>handleMenu()}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Navbar.Collapse id="basic-navbar-nav" className={`${menuOpened ? "show" : ""}`}>
                    <Container>
                        <Link to="#about" className="nav-link" onClick={()=>closeMenu()}><span>01.</span>About</Link>
                        <Link to="#solutions" className="nav-link" onClick={()=>closeMenu()}><span>02.</span>Solutions</Link>
                        <Link to="#services" className="nav-link" onClick={()=>closeMenu()}><span>03.</span>Services</Link>
                        <Link to="#providers" className="nav-link" onClick={()=>closeMenu()}><span>04.</span>Providers</Link>
                        <Link to="#contact" className="nav-link" onClick={()=>closeMenu()}><span>05.</span>Contact</Link>
                        <Link to="#careers" className="nav-link" onClick={()=>closeMenu()}><span>06.</span>Careers</Link>
                    </Container>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;
